<template>
  <div class="bg">
    <Navbar title="vip" />
    <Swiper ref="mySwiper" :options="swiperOptions" class="swiper">
      <SwiperSlide v-for="item, index in levelData" :key="`img_${index}`" class="swiper-slide">
        <img :src="verConfig.url + item.picture" alt="" width="100%" height="100%" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Tab,
  Tabs,
  Image,
  NoticeBar,
  Field,
  DropdownMenu,
  DropdownItem,
  Popup,
  DatetimePicker,
  Toast
} from 'vant'
import { post, get, dealError } from '@/utils/newRequest'
import Navbar from "@/components/JNav"
// import Swiper from 'swiper'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper.min.css'
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    Toast,
    Navbar,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      levelData: [],
      backgroundArr: ['#bcbdcd', '#edccbb', '#d2d0e8', '#83d4f7', '#f1d178', '#a794f8'],
      orderNumObj: {
        0: 1,
        1: '1~3',
        2: '1~6',
        3: '1~7',
        4: '1~8',
        5: '1~9'
      },
      swiperOptions: {
        spaceBetween: -10,
        loop: false,
        slidesPerView: 1.2,
        centeredSlides: true
      }
    }
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    async getLevels() {
      try {
        const res = await get("/users/ranks");
        this.levelData = res;
        console.log(res)
      } catch (error) {
        dealError(error)
      }
    },
  },
  created() {
    this.getLevels();
  },
  mounted() {
    // this.swiper.slideTo(3, 1000, false)
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  destroyed() {

  },
}
</script>

<style lang="less" scoped>
.bg {
  // background-color: #f7f8f9;
  min-height: 100vh;
  background: #092152;

  .swiper {
    width: calc(100vw - 32px);
    height: 70vh;
    margin: 16px;
    padding: 40px 0;

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      width: 300px;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      transition: 300ms;
      transform: scale(0.8);
      border-radius: 8px;
    }

    .swiper-slide-active,
    .swiper-slide-duplicate-active {
      transform: scale(1);
    }
  }
}
</style>